<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('order_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('invoice_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type) || $route.name === 'FraudOrdersAll'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							v-if="$route.name === 'FraudOrdersAll' || admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_type')">-->
							{{ translate('user_type') }}
							<!--<sort field="user_type" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('username')">-->
							{{ translate('username') }}
							<!--<sort field="username" />-->
						</th>
						<th
							v-if="$route.name === 'FraudOrdersAll' || admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('full_name')">-->
							{{ translate('full_name') }}
							<!--<sort field="full_name" />-->
						</th>
						<th
							v-if="$route.name !== 'FraudOrdersAll'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('ship_to')">-->
							{{ translate('ship_to') }}
							<!--<sort field="ship_to" />-->
						</th>
						<th
							v-if="$route.name !== 'FraudOrdersAll'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('tracking_number')">-->
							{{ translate('tracking_number') }}
							<!--<sort field="tracking_number" />-->
						</th>
						<th
							v-if="$route.name !== 'FraudOrdersAll'"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('payment_method')">-->
							{{ translate('payment_method') }}
							<!--<sort field="payment_method" />-->
						</th>
						<th
							v-if="$route.name === 'FraudOrdersAll' && !admin.includes($user.details().type)"
							class="border-top-0">
							{{ translate('is_personal_sponsored') }}
						</th>
						<th
							class="border-top-0 text-right">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('status')">-->
							{{ translate('status') }}
							<!--<sort field="status" />-->
						</th>
						<th
							v-if="showCountryFilter"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!-- @click="sortByField('country')" -->
							{{ translate('country') }}
							<!-- <sort field="country" /> -->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date')">-->
							{{ translate('order_date') }}
							<!--<sort field="date" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date_refunded')">-->
							{{ translate('date_refunded') }}
							<!--<sort field="date_refunded" />-->
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							{{ item.id }}
						</td>
						<td class="align-middle">
							{{ item.attributes.invoice_id }}
						</td>
						<td
							v-if="admin.includes($user.details().type) || $route.name === 'FraudOrdersAll'"
							class="align-middle">
							<span
								v-if="item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="$route.name === 'FraudOrdersAll' || admin.includes($user.details().type)"
							class="align-middle">
							{{ translate(item.attributes.user_type) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							v-if="$route.name === 'FraudOrdersAll' || admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td
							v-if="$route.name !== 'FraudOrdersAll'"
							class="align-middle">
							{{ item.attributes.ship_to }}
						</td>
						<td
							v-if="$route.name !== 'FraudOrdersAll'"
							class="align-middle text-left">
							<template v-if="(item.attributes.tracking.length != 0)">
								<template
									v-if="shippingMethodsLinkable.includes(item.attributes.tracking['carrier_code'])">
									<a
										:href="shippingMethodsLinks[item.attributes.tracking['carrier_code']](item.attributes.tracking['tracking_number'])"
										target="_blank">
										{{ item.attributes.tracking['tracking_number'] }}
									</a>
								</template>
								<template v-else>
									{{ item.attributes.tracking['tracking_number'] }}
								</template>
							</template>
						</td>
						<td
							v-if="$route.name !== 'FraudOrdersAll'"
							class="align-middle">
							{{ translate(item.attributes.payment_method) }}
						</td>
						<td
							v-if="$route.name === 'FraudOrdersAll' && !admin.includes($user.details().type)"
							class="align-middle">
							{{ translate(`${!!item.attributes.is_personal_sponsored}`) }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td
							v-if="showCountryFilter"
							class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.updated_at.date ? $moment(item.attributes.updated_at.date).format(dateFormat) : $moment(item.attributes.updated_at).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report, Grids, Users, Countries,
} from '@/translations';
import {
	MDY_FORMAT as mdyFormat, YMDHMS_FORMAT,
} from '@/settings/Dates';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import FraudOrders from '@/util/Report';

export default {
	name: 'FraudOrdersAll',
	messages: [Report, Grids, Users, Countries],
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			FraudOrdersAll: new FraudOrders(),
			dateFormat: YMDHMS_FORMAT,
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.FraudOrdersAll.data.loading;
		},
		pagination() {
			return this.FraudOrdersAll.data.pagination;
		},
		data() {
			try {
				const { data } = this.FraudOrdersAll.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		showCountryFilter() {
			return (this.admin.includes(this.$user.details().type));
		},
	},
	mounted() {
		this.$parent.rangeStart = '';
		this.$parent.rangeEnd = '';
		this.FraudOrdersAll.getAllFraudOrders().then(() => {
			const { start, end } = this.FraudOrdersAll.data.response.data.meta.period_dates;
			this.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.FraudOrdersAll.getAllFraudOrders(options);
		},
	},
};
</script>
